///-------------------------------------------------------------------------------------------------
// summary:	Authentication service class
///-------------------------------------------------------------------------------------------------

import Api from './Api';

export default {
  
  // --------------------------------------------------------------------------------[ public calls ]
  getIntegrationInfo( domain , key ) {
    const response = Api().get(`/public/integration/${domain}`, { params: { key:key }});
    return response;
  },

  // take our known values and generate a shift4 access token; this is a one-time use token, for a single transaciton.
  getClientAccessToken( domain, key, email ) {
    const response = Api().get( `/public/integrationClientAccessToken/${domain}`, { params: { key: key, email: email }});
    return response;
  },

  getActiveFunds(domain) {
    const response = Api().get(`/public/funds/${domain}`);
    return response;
  },

  getFund(domain, id) {
    const response = Api().get(`/public/fund/${domain}/${id}`);
    return response;
  },

  getPaymentToken( domain ) {
    const response = Api().get(`/public/paymentToken/${domain}`); // note: this method was auto generated (replace with the real call, wolfie)
    return response;
  },
  
  makePayment(domain, payload ) {
    const response = Api().post(`/public/integrationTransaction/${domain}` , payload );
    return response;
  },
  
  requestRegistration( payload ) {
    const response = Api().post(`/public/requestRegistration`, payload );
    return response;
  }

  // -------------------------------------------------------------------------[ authenticated calls ]


}
