import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { BootstrapVue, IconsPlugin , BootstrapVueIcons } from 'bootstrap-vue'
import VueLodash from 'vue-lodash'
import _ from 'lodash'

// import '@babel/polyfill'
// import 'mutationobserver-shim'

// import {version} from "./../package.json";
let {version,releaseDate}     = require('./../package.json');
// let releaseDate = require('./../package.json').releaseDate;

// This is nice in one regard and a pain in the other. good grief!
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

// import { faSpinner, faAlignLeft } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

// iFrame integration: sandbox='allow-scripts allow-modal' loading='eager'

Vue.config.productionTip = false

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import "./assets/css/colors.css";
import "./assets/css/main.css";

// import './plugins/bootstrap-vue'

Vue.prototype._ = _;

Vue.use(VueLodash, { name: 'custom' , lodash: _ })

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVueIcons)
Vue.use(VueSweetalert2);

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {}); // config is optional. more below
import "vue-wysiwyg/dist/vueWysiwyg.css";

const host         = window.location.host;
const urlParts     = host.split('.');
const domainLength = urlParts.length;       //3; // route1.example.com => domain length = 3

new Vue({
  router,
  render: h => h(App),
  data: {
    appVersion  : version,
    releaseDate : releaseDate,
    host        : host,
    urlParts    : urlParts,
    domainLength: domainLength,
    tenantName  : "",
    cart        : null
  },
  methods: {
    subDomain() {
      // console.log("host [%s]" , this.host);
      // for( let i = 0; i < this.urlParts.length; i++ ) {
      //   console.log("Part %s = [%s]" , i, this.urlParts[i] ); 
      // }
      if( this.urlParts.length > 2 ) {
        return this.urlParts[0];
      }
      return "";
    },
    async login(user) {
      // fixme: store the token, and setup the other stored data here.  
    },
    async confirmYesNo( title , icon = "question" , cancelButtonText = "No" , confirmButtonText = "Yes" ) {
      let result = await this.$swal( {
                                            icon: icon,
                                            title: title,
                                            showCancelButton:true,
                                            cancelButtonText: cancelButtonText,
                                            confirmButtonText: confirmButtonText,
                                            allowEscapeKey: true,
                                            allowEnterKey: true
                                          });

      if( result.dismiss ) {// <iframe id="InlineFrame1" name="InlineFrame1" src="https://lutheranhaven.donations.express/donations/407001" width="1200" height="800" frameborder="0"></iframe>
        return false;
      }

      return true;
    },
    async confirm( title , icon = "question" ) {
      let result = await this.$swal( {
                                            icon: icon,
                                            title: title,
                                            showCancelButton:true,
                                            allowEscapeKey: true,
                                            allowEnterKey: true
                                          });

      if( result.dismiss ) {
        return false;
      }

      return true;
    },
    async alert( title , icon = 'success') {
      let result = await this.$swal( {
                                            icon: icon,
                                            title: title,
                                            allowEscapeKey: true
                                          });
    },
  }
}).$mount('#app')
