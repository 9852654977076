<template>
  <div style="height: 100%; width: 100%" class="nof-secureSite-content" >
    <!-- <div class="mt-0">
      <b-navbar toggleable="md" type="light" variant="light" sticky>
        <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
        <b-navbar-brand class="ml-1">
          Donations Express
        </b-navbar-brand>
        <b-collapse id="nav-text-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-text class="mr-2" tabs align="right" v-b-modal.modal-3>LogIn</b-nav-text>
            <b-nav-text class="ml-5" tabs align="right" v-b-modal.modal-7>FAQ</b-nav-text>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div> -->
    <img
      style="margin-top: 2em; width:80%;max-width:500px;"
      alt="Donations Express"
      src="@/assets/img/donations-express-logo2-dark-512.png"
    />
    <br />
    <div>
      <!-- <div >
        Version: {{ $root.appVersion }}
      </div> -->
      <!-- <h1 class="nof-header">Donations Express</h1> -->

      <!-- <img class="home" alt="donations" style="width:80vw;max-width:256px;" src="@/assets/img/donations-express-logo-dark-512.png"> -->

      <div class="body-content" style="margin-top:2em;margin-left: auto;margin-right: auto;text-align: left;">
        <h3 style="text-align:center;">Introducing The Power of Giving</h3>
        <h4 style="text-align:center;">Transforming Communities, One Contribution at a Time</h4>

        <p style="margin:2em;">
          Are you ready to make a difference? A versatile fundraising solution, tailored to meet your unique needs, 
          empowering you to achieve your goals and make a lasting impact. Here's how you can harness the power of Donations Express:
        </p>

        <b-row>
          <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <ul style="margin-top:4%;list-style-type: none;">
              <li><b>Senior Living</b>: Elevate ongoing fundraising campaigns to support the vibrant community within senior living facilities. With our platform, you can create a seamless giving experience that fosters a sense of unity and support among residents and their families.</li>
              <li style="margin-top: 10px"><b>Hospitals</b>: From vital fundraisers to special drives and innovative development campaigns, our platform provides the tools to rally support for crucial healthcare initiatives, ensuring that hospitals can continue to provide exceptional care to their communities.</li>
              <li style="margin-top: 10px"><b>Schools</b>: Whether it's for special occasions, band and athletic needs, or other essential resources, our platform empowers schools to raise funds efficiently and effectively, enriching the educational experience for students and faculty alike.</li>
              <li style="margin-top: 10px"><b>Churches</b>: Fuel missionary endeavors, host unforgettable special events, embark on transformative building projects, and gather general contributions with ease. Our platform helps churches cultivate a culture of generosity and purposeful giving.</li>
            </ul>
          </b-col>
          <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div style="display: flex;justify-content: center; align-items: center;">
              <img
                style="width:90%;border-radius:1em;box-shadow: 2px gray; margin-left:auto;margin-right:auto;margin-bottom:2em;"
                alt="Giving"
                src="@/assets/img/giving2.png"
              />
            </div>
            <p>Join the movement and unleash the potential of giving with our innovative fundraising solution. Together, let's create positive change and build stronger, more vibrant communities.</p>
        
            <p style="font-weight:800;color:firebrick;">Onboarding and setup assistance is free!</p>

            <p>Call or email:  <a href="tel:601.806.2500">601.806.2500</a> or <a href="mailto:info@donations.express?subject=Donations Express Web Inquiry">info@donations.express</a></p>
              <!-- <h4>Pricing</h4> -->
              <!-- <p>Starter plans are free up to 2 campains. </p> -->
            <!-- <p v-if="subDomain">Calling from: {{ subDomain }}</p> -->
          </b-col>
        </b-row>
       
      </div>

      <h1 class="mt-0 mb-0">Available Plans</h1>
      <b-row align-v="center" class="container" style="margin:auto;text-align:center;">
        <div class="" >
          <b-card-group deck class="mb-3 row justify-content-center" style="">
            <!-- card 1 -->
            <b-card
              border-variant="default"
              header="Basic"
              header-bg-variant="info"
              header-text-variant="white"
              align="center"
              title="$0/month"
              tag="article"
              style="max-width: auto"
              class="mb-5 mt-2 col-md-12 col-lg-3"
            >
              <br />
              <b-card-text>
                No charge for campaigns<br>
                Around 4% handling fee*<br>
                No Long-Term Commitment<br>
              </b-card-text>
              <br/>
              <div style="margin-top:1em;margin-bottom:1em;font-size:.8em;color:gray;">
                * Varies: less, but not more than 4%
                depending on payment method
              </div>
              <br />
              <b-button v-b-modal.modal-2 variant="secondary" @click="getInfo('fremium')">
               More Information
              </b-button>
              <br />
              
            </b-card>
            <!-- card 1 ends here-->
            <!-- card 2 -->
            <b-card
              border-variant="warning"
              header="Silver"
              header-bg-variant="secondary"
              header-text-variant="black"
              align="center"
              title="$49/month"
              tag="article"
              style="max-width: auto"
              class="mb-5 mt-2 col-md-12 col-lg-3"
            >
              <br />
              <b-card-text>
                All features of Basic<br>
                Real-time Activity Statistics<br>
                Donor Statistics<br>
              </b-card-text>
             
              <br />
              <b-button v-b-modal.modal-1 variant="success" @click="getInfo('basic')">More Information</b-button>
              <br />
            </b-card>
            <!-- card 3 -->
            <b-card
              border-variant="default"
              header="Gold"
              header-bg-variant="warning"
              header-text-variant="white"
              align="center"
              title="Call us"
              tag="article"
              style="max-width: auto"
              class="mb-5 mt-2 col-md-12 col-lg-3"
            >
              <br />
              <b-card-text>
                All features of Silver<br>
                Detailed Campaign Statistics<br>
                Donor Management<br>
                Mailing List Management<br>
                QR Code Generator<br>
                Direct Fund/Purpose Management<br>
                Offline Donation Management<br>
              </b-card-text>
             
              <br />
              <b-button v-b-modal.modal-1 variant="secondary" @click="getInfo('premium')">
                More Information
              </b-button>
            </b-card>
          </b-card-group>
        </div>
      </b-row>
    </div>
    <!-- <b-button v-b-modal.login1>Sign In</b-button> -->
    <!-- <b-button @click="$router.push('Login')">Sign In</b-button> -->

    <!-- <b-modal id="login1" hide-footer hide-header centered size="lg" style="border-radius:2em;">
    </b-modal> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import LoginCard from "@/components/LoginCard.vue"
import GeneralSerice from "@/services/GeneralService.js";

export default {
  name: "Home",
  components: {
    // LoginCard
  },
  data() {
    return {
      message: "Welcome to Donations Express",
      subDomain: "",
    };
  },
  created() {
    this.subDomain = this.$root.subDomain();
  },
  methods: {
    getInfo( t  ) {
      let url = 'https://www.newcare.international#donations-express-' + t;
      window.location.href = url;
    },
    addUser(e) {
      // console.log("Home.addUser()", e);
    },
    async submitForm() {
      try {
        const response = await GeneralSerice.requestRegistration({
          application: "donations-express",
          email      : this.email,
          name       : this.name,
        });
        // Handle the response as needed
        console.log('Signup successful:', response);
      } catch (error) {
        console.error('Error signing up:', error);
      }
    },
    hasSubdomain() {
      if (this.subDomain && this.subDomain !== "www") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>

.center-it {
        margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
}

body {
  font-size: 12pt;
  background-repeat: repeat-y;
  background-position: 50% 0%;
  background-color: rgb(239, 238, 233);
  background-image: none;
  font-family: Lucida Sans;
  font-weight: normal;
  font-style: normal;
  padding-bottom: 1px;
}

  /* Default width for page width <= 800px */
  .body-content {
    width: 100%;
    padding:2em;
  }

  /* Adjust width for page width > 800px */
  @media (min-width: 1001px) {
    .body-content {
      width: 70%;
    }
  }

.nof-secureSite-content {
  background-color: rgb(237, 237, 237);
  color: black;
  padding: 2px;
  border-left: 2px solid rgb(127, 186, 243);
  border-right: 2px solid rgb(127, 186, 243);
}

.nof-header {
  // color: rgb(173,199,238);
  color: rgb(0, 166, 189);
  padding: 5px;
}

.nof-text {
  color: rgb(58, 58, 85);
}
</style>
