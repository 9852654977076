import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import About from '../views/About.vue'
// import Donations from '../views/Donations.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { authRequired: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { authRequired: false }
  },
  {
    path: '/donations/:domain?/:tenantKey?/:tileId?',
    name: 'donations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props    : true,
    component: () => import(/* webpackChunkName: "donations" */ '../views/Donations.vue'),
    // component: Donations,
    meta     : { authRequired: false }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { authRequired: false }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log( "to" , to );
  const authRequired = to.matched.some(route => route.meta.authRequired);
  let token;// = null

  try {
    if (to.matched.some(route => route.meta.removeToken)) {
      // console.log('******** Hit unknown route ** removing token')
      localStorage.removeItem('x-access-token')
    } else {
      try{
      token = localStorage.getItem('x-access-token');   // if we're running in an iFrame??
      } catch( error ) {
        token = sessionStorage.getItem('x-access-token');
      }
      // console.log( "token" , token );
    }
  } catch( error ) {
    console.error( error.message );
    token = null;
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (authRequired && !token) {
    // console.log('******** Hit authRequired route - no token - going to root')
    next('/');
  } else {
    // console.log( "moving along" );
    next();
  }
})

export default router
